<template>
  <!-- 顶部导航 -->
  <div class="header" id="header">
    <div class="navigation content-box gn-flex-between">
      <div class="nav-left">
        <a href="/home">
          <img :src="logo" alt="广农数链信息科技有限公司" v-if="logo" />
          <h1 v-show="false">广农数链信息科技有限公司</h1>
        </a>
      </div>
      <div class="nav-right gn-flex-center">
        <span class="icon-name">菜单</span>
        <a-icon class="icon" type="menu" v-if="!isLargeScreen" @click="showDrawer" />
        <ul class="nav-list gn-flex-center" v-else >
          <li v-for="(item, i) in nav" :key="i" class="nav-item gn-flex-center" :class="{ current: checkRouter(item) }">
            <router-link :to="item.href">{{ item.title }}</router-link>
            <ul
              class="dropdown"
              v-if="item.children.length > 0"
            >
              <li v-for="(jt, j) in item.children" :key="j">
                <router-link :to="jt.href">{{ jt.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- 菜单抽屉 -->
    <a-drawer
      placement="right"
      :closable="false"
      :visible="visible"
      @close="onClose"
      :width="'60vw'"
      :headerStyle="{ height: '50px' }"
    >
      <div slot="title">
        <a href="/">
          <img :src="logo" alt="广农数链" />
        </a>
      </div>
      <a-menu
        style="width: 100%"
        :default-selected-keys="defaultSelected"
        :open-keys.sync="openKeys"
        mode="inline"
      >
        <!-- 一级菜单自定义 -->
        <a-menu-item key="/home" @click="goTop()">
          <router-link to="/" style="display:block">
            <span>首页</span>
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item key="recruitAgent" @click="goTop()">
          <router-link to="/recruitAgent" style="display:block">
            <span>招募代理</span>
          </router-link>
        </a-menu-item> -->

        <!-- 二级菜单 -->
        <a-sub-menu v-for="(item) in formatNav" :key="item.href" @click="goTop()">
          <span slot="title">{{ item.title }}</span>
          <a-menu-item v-for="ite in item.children" :key="ite.key">
            <!-- <router-link :to="ite.href" style="display:block">{{ ite.title }}</router-link> -->
            <a href="javascript:;" @click="toMenuPage(ite)">{{ ite.title }}</a>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-drawer>
  </div>
</template>

<script>
import { backTop } from '@/utils/backTop'
import { menuList, getMenuPath } from '@/utils/website'
import { mapGetters } from 'vuex'
import { addView } from '@/api/officialWebsite'

export default {
  name: 'Header',
  components: {},
  computed: {
    formatNav () {
      const list = JSON.parse(JSON.stringify(this.nav))
      list.shift() // 移除 首页 菜单，因为这是一级菜单，需要自定义
      // list.shift() // 移除 招募代理 菜单，因为这是一级菜单，需要自定义

      return list
    },
    isLargeScreen () {
      return this.$store.state.IS_LARGE_SCREEN
    },
    ...mapGetters(['WEBSITE_MENU', 'FLATTEN_MENU', 'WEBSITE_BANNER'])

  },
  watch: {
    $route: {
      async handler (to, from) {
        this.onClose()
        // backTop()
        if (!this.FLATTEN_MENU.length) {
          console.log('header获取菜单')
          await menuList()
        }
        // 路径home被重定向到""
        const path = getMenuPath(this.$route.path) || '/home'
        const Item = this.FLATTEN_MENU.find(item => item.href === path)
        // 设置meta用于seo
        if (Item) {
          this.meta_setting = {
            title: Item.seo_title || Item.title,
            keywords: Item.seo_key_words || '',
            description: Item.seo_desc || ''
          }
          // 增加浏览量
          addView({ code: Item.key || Item._p_key }).then(res => { }).catch(err => console.error(err))
        }
      },
      immediate: true // 立即执行
    }
  },
  metaInfo () {
    return {
      title: this.meta_setting.title || this.$route.meta.title,
      meta: [
        { name: 'keywords', content: this.meta_setting.keywords },
        { name: 'description', content: this.meta_setting.description }
      ]
    }
  },
  data () {
    return {
      nav: [],
      logo: '',
      meta_setting: {
        title: '',
        keywords: '',
        description: ''
      },
      visible: false,
      defaultSelected: [], // 默认选择,
      openKeys: []
    }
  },
  async created () {
    if (!this.WEBSITE_BANNER.length) {
      await this.$store.dispatch('getWebsiteBannerList')
    }
    this.logo = this.WEBSITE_BANNER[3].list[0].title_img
    // 获取logo
    this.nav = await menuList()
    console.log(this.nav, '菜单')
    this.$set(this, 'defaultSelected', [getMenuPath(this.$route.path)])
    // console.log('header WEBSITE_MENU:', this.WEBSITE_MENU)
    // console.log('header FLATTEN_MENU:', this.FLATTEN_MENU)
  },
  mounted () {
    const header = document.getElementById('header')
    this.$store.commit('getHeaderHeight', header.offsetHeight)
  },
  methods: {
    // 回到顶部
    goTop () {
      backTop()
    },
    // 判断-> 当前项是否被选中
    checkRouter (item) {
      const path = getMenuPath(this.$route.path)// 页面当前路由
      let flag = false
      if (!path && item.href === '/home') {
        flag = true
      }
      if (path === item.href) {
        flag = true
      }
      if (!flag) {
        item.children.forEach((item) => {
          if (path === item.href) {
            flag = true
          }
        })
      }
      return flag
    },
    // 打开抽屉
    async showDrawer () {
      this.visible = true
    },
    // 关闭抽屉
    onClose () {
      this.visible = false
    },
    toMenuPage (subItem) {
      let type = ''
      if (subItem.href.startsWith('/information')) { // 公司新闻
        return this.toMobilePage('mobileNews', type = '0')
      }
      if (subItem.href.startsWith('/industryDynamics')) { // 行业动态
        return this.toMobilePage('mobileNews', type = '1')
      }
      if (subItem.href.startsWith('/videoShow')) { // 视频展示
        return this.toMobilePage('mobileNews', type = '2')
      }

      this.$router.push({ path: subItem.href })
    },
    toMobilePage (pathname, type) {
      this.$router.push({
        name: pathname,
        query: {
          type: type ?? ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fz-24 {
  font-size: 24px;
}
::v-deep .ant-drawer-title {
  height: 50px;
  div,
  a {
    height: calc(100% - 2px);
    display: inline-block;
    padding: 5px;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 160px;
  }
}
::v-deep .ant-drawer-header {
  padding: 0;
}
::v-deep .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

::v-deep .ant-menu-item-selected::after {
  display: none !important;
}

::v-deep .ant-menu-item-selected {
  background: #f3f4f5 !important;
  color: #00a0ac;
}

::v-deep .ant-menu-submenu-title:hover,
::v-deep .ant-menu-submenu-active,
::v-deep .ant-menu-submenu-selected {
  color: #00a0ac;
}

::v-deep .ant-menu-submenu-arrow::after,
::v-deep .ant-menu-submenu-arrow::before {
  background: #00a0ac !important;
}

.header {
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  box-sizing: border-box;
  padding: 0 50px;

  .navigation {
    margin: 0 auto;
    height: 100%;
    margin: 0 auto;
    padding: 0 6px;
    padding-right: 12px;
    .nav-left {
      height: 100%;
      padding: 6px 0px 7px;
      img {
        height: 100%;
      }
    }
    .nav-right {
      height: 100%;
      .icon-name{
        display: none;
      }
      .nav-list {
        display: flex;
        height: 100%;
        > li {
          font-size: 16px;
          position: relative;
          // display: inline-block;
          height: 68px;
          &.current {
            // background: #00a0ac;
            // color: #fff !important;
            border-bottom: 3px solid #00a0ac;
          }
          > a {
            width: 120px;
            height: 36px;
            // border-radius: 4px;
            display: block;
            line-height: 36px;
            text-align: center;
            color: #000000;

            &:hover {
              color: #00a0ac;
            }
          }
          .dropdown {
            z-index: 9;
            position: absolute;
            top: 68px;
            left: -10px;
            display: none;
            li {
              height: 50px;
              a {
                display: block;
                min-width: 140px;
                height: 100%;
                padding: 0 15px;
                text-align: center;
                line-height: 50px;
                color: #000000;
                background: white;
                white-space: nowrap;
                &:hover {
                  background: #eeeeee;
                }
              }
            }
          }
        }
      }
    }
  }
}
.nav-item:hover .dropdown {
  display: block!important;;
}
</style>

<style lang="scss" scoped>
@import './mediaStyle/header';   // 移动端适配样式
</style>
